/**************************
***** Global Settings *****
**************************/

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Lobster&family=Pacifico&display=swap');

:root {
  --color_bg_secondary : rgb(255, 145, 77);
  --color_bg_primary : rgb(252,210,153);
  --color_text : rgb(0, 0, 0);
  --color_border : rgb(255, 22, 22);
  --font_family_headers : 'Josefin Sans', sans-serif;
  --font_family_text : 'Lobster', cursive;
}

/* 'Pacifico', cursive    for the other one */

@font-face {
    font-family: OpenDyslexic;
    src: url("./components/fonts/OpenDyslexic-Regular.otf") format("opentype");
}

body {
  background-color : var(--color_bg_primary);
  padding : 0px 5%;
}

p, 
li,
form,
td,
th,
a {
    font-family: var(--font_family_text);
    color : var(--color_text);
}

h1,
h2,
h3, 
h4,
button {
    font-family: var(--font_family_headers);
    color : var(--color_text);
}



/*******************************************************************/
/************************* Mobile First ****************************/
/*******************************************************************/

/** The order is : 
- Navbar
- Home Page
- FAQ Page
- Question Creation
- Answer Creation
- Register
- Connexion
- Contact Page
**/

/*** Navbar ***/

.nav_btns {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.access_btns button {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.dash {
  margin:0 1rem;
}

.btn_deconnexion {
  font-size: 1.2rem;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 0 1rem 0;
  padding: 0
}

/* there is some dynamic css in the Navbar composant
 to modify the "principal" nav li */
nav li {
  padding: 0.5rem;
  align-self: flex-end;
}

nav li a {
  text-decoration: none;
}

/*** Home Page ***/

.art_bio {
  background-color: var(--color_bg_secondary);
  border: 8px solid var(--color_border);
  border-radius: 25%;
  padding: 2rem;
  margin-bottom: 1rem;
}

.art_bio img {
  border : 4px solid black;
  margin-left:10%;
}

.art_bio h2 {
  text-decoration: underline;
  margin-left: 1rem;
}

.home_photo {
  width:auto;
  max-width:80%;
  height:auto;
  max-height:80%
}

.table_home {
  width:50%;
  margin:auto;
}

.table_home, 
.table_home td {
  border: 2px solid black
}

.td_txt {
  text-align: center;
}

/*** FAQ Page ***/

.art_faq {
  margin-bottom: 2rem;
}

.navlink_question a {
  font-size: 1.5rem;
  margin : 2rem
}

.question {
  background-color: var(--color_bg_secondary);
  border: 4px solid var(--color_border);
  border-radius: 25%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  width : 60%
}

.question_flex, 
.answer_flex {
  display:flex
}

.question_btns,
.answer_btns {
  margin:auto;
}

.question_btns button,
.answer_btns button {
  font-size: 1.5rem;
}

.answer_btns button {
  margin: 0.5rem auto;
}

.question_btns button {
  margin : auto 0 1rem 0.5rem
}

.no_answer {
  background-color: var(--color_border)
}

.privateInfos {
  margin-left: 10%;
}

.answer {
  background-color: var(--color_bg_secondary);
  border: 4px solid var(--color_border);
  border-radius: 25%;
  padding: 1.5rem;
  width : 60%;
  margin-left:10%
}

/*** Question Creation ***/

.question_title {
  margin-bottom: 1.5rem;
}

.question_submit {
  font-size: 1.2rem;
  margin:1rem
}

/*** Answer Creation ***/

.answer_title {
  margin-bottom: 1.5rem;
}

.answer_submit {
  font-size: 1.2rem;
  margin:1rem
}

/*** Register ***/

.register_submit {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

/*** Connexion ***/

.form_connexion_field {
  margin-bottom: 1rem;
}

.forgot_password {
  font-size: 1.5rem;
  display:block
}

.connexion_submit {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

/*** Contact ***/

.mappy {
  width:100%;
  height:30rem;
}

.symbol {
  width:2rem;
  height:2rem;
}

.btn_contact {
  font-size: 1.2rem;
  margin : 1rem;
}

.contact_infos {
  margin: 1rem 0;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.contact_infos > * {
  margin-bottom: 1rem;
}
/*******************************************************************/
/************************ Desktop **********************************/
/*******************************************************************/

@media (min-width: 1024px){

  /*** Navbar ***/ 

  nav ul {
    margin: 0 0 1rem 5%;
  } 

  .nav_btns {
    justify-content: flex-end;
  }

  .access_btns {
    display: flex;
    justify-content: flex-end;
  }

  .access_btns button {
    font-size: 1.5rem;
    margin : 0 0 0 1rem
  }

  /*** Home Page ***/

  .art_div_flex {
    display:flex;
    justify-content: space-between;
  }

  .art_even .art_div_flex {
    flex-direction:row-reverse;
  }

  .art_bio {
    padding:3rem;
  }

  .art_bio img {
    margin:auto 1rem; /* à modifier pour déplacer */
  }

  .art_bio h2 {
    margin-left: 4rem;
  }
  
  .home_photo {
    width:auto;
    max-width:30%;
    height:30%;
  }

  .home_text {
    align-self: center;
  }

  .div_localisation {
    display:flex;
    flex-direction: column;
    column-gap: 1rem;
    align-items: center;
  }

  /*** FAQ Page ***/

  .answer {
    width : 60%;
    margin-left:15%
  }

  .patient_faq {
    display:flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: center;
  }

  .question {
    order: 1;
    width:100%
  }

  .privateInfos {
    order: 1;
    margin-right : 5%
  }

  .question_btns {
    order:2;
    display: flex;
    flex-direction: column;
    margin-left:5%
  }

  .answer_btns {
    margin: auto 0 auto 20% ;
  }

  /*** Contact Page ***/

  .contact_infos {
    flex-direction: row;
    justify-content: space-around;
  }
  
}